import { GlobalRole } from '@itm/shared-frontend/lib/types';

export type {
  GlobalRole,
  TableSortParams,
  TableChangeParams,
  SelectOption,
  ServerValidationError,
  ServerValidationResponse,
  ServerError,
  FieldErrors,
  ServerFormErrors,
  ExternalFilters,
  TanstackTableChangeParams,
} from '@itm/shared-frontend/lib/types';

export enum LocalStorageKeys {
  ConfigurationParameterListTable = 'CONFIGURATION_PARAMETER_LIST_TABLE',
  ConfigurationValidationListTable = 'CONFIGURATION_VALIDATION_LIST_TABLE',
  ConfigurationUploadHistoryListTable = 'CONFIGURATION_UPLOAD_HISTORY_LIST_TABLE',
  ConfigurationParameterBenefitSectionListTable = 'CONFIGURATION_PARAMETER_BENEFIT_SECTION_LIST_TABLE',
  ConfigurationParameterPensionElementListTable = 'CONFIGURATION_PARAMETER_PENSION_ELEMENT_LIST_TABLE',
  ConfigurationScoringDefinitionScoreTypeTable = 'CONFIGURATION_SCORING_DEFINITION_SCORE_TYPE_TABLE',
  ConfigurationScoringDefinitionDataItemTable = 'CONFIGURATION_SCORING_DEFINITION_DATA_ITEM_TABLE',
  ConfigurationScoringDefinitionValidationGroupingTable = 'CONFIGURATION_SCORING_DEFINITION_VALIDATION_GROUPING_TABLE',
  DataManagementUploadHistoryTable = 'DATA_MANAGEMENT_UPLOAD_HISTORY_TABLE',
  DataManagementAnalysisHistoryTable = 'DATA_MANAGEMENT_ANALYSIS_HISTORY_TABLE',
  ReportHistoryTable = 'REPORT_HISTORY_TABLE',
  ReportTemplateTable = 'REPORT_TEMPLATE_TABLE',
  ReportTemplateHistoryTable = 'REPORT_TEMPLATE_HISTORY_TABLE',
}

export enum TargetPlatform {
  AWS = 'AWS',
  AZURE = 'AZURE',
}

export enum SearchOrder {
  asc,
  desc,
}

export enum Role {
  SuperAdmin = 'SuperAdmin',
  Support = 'Support',
  Analyst = 'DataAudit_Analyst',
  ClientAdmin = 'DataAudit_ClientAdmin',
  Manager = 'DataAudit_Manager',
}

export type UserPermissionResponse = {
  userId: string;
  companyId: string;
  companyName: string;
  schemeId: string;
  schemeName: string;
  productId: string;
  productName: string;
  productRoleId: string;
  productRoleName: GlobalRole;
  userProductRoleId: string;
  assignmentDate: string;
};

export type ProductInfoResponse = {
  id: string;
  description: string | null;
  version: string | null;
};

export enum ParameterStatus {
  SchemeDetailsUpdated = 'SchemeDetailsUpdated',
  SchemeDetailsNotUpdated = 'SchemeDetailsNotUpdated',
  BenefitSectionsUpdated = 'BenefitSectionsUpdated',
  BenefitSectionsNotUpdated = 'BenefitSectionsNotUpdated',
  PensionElementsCreated = 'PensionElementsCreated',
  PensionElementsNotCreated = 'PensionElementsNotCreated',
}

export enum TemplateStatus {
  Draft = 'Draft',
  Published = 'Published',
  Archived = 'Archived',
}

export enum ReportStatus {
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
}

export enum ReportType {
  Generated = 'Generated',
  Uploaded = 'Uploaded',
}

export type SearchAllParametersResponse = {
  schemeId: string;
  name: string | null;
  createdDate: string;
  updatedDate: string | null;
  statuses: ParameterStatus[];
};

export type SearchAllParametersResponsePagedResponse = {
  totalCount: number;
  items: SearchAllParametersResponse[];
};

export type SchemeResponse = {
  id: string;
  companyId: string;
  name: string | null;
};

export type CompanyResponse = {
  id: string;
  name: string;
};

export enum UploadStatus {
  InProgress = 'InProgress',
  Failed = 'Failed',
  Completed = 'Completed',
}

export enum AnalysisStatus {
  NotStarted = 'NotStarted',
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed',
}

export type SourceFilesResponsePagedResponse = {
  totalCount: number;
  items: SourceFilesResponse[];
};

export type SourceFilesResponse = {
  id: string;
  name: string | null;
  uploadType: string;
  uploadedDate: string;
  schemeId: string;
  uploadedByUserName: string;
  uploadStatus: UploadStatus;
  analysisStatus: AnalysisStatus;
  errorMessage?: string;
  totalActive: number;
  totalPreservedDeferred: number;
  totalInPayment: number;
  totalDependent: number;
  totalMembers: number;
  schemeName?: string;
};

export type UpdateSchemeDetailsDto = {
  startDate: string;
  renewalDate: string;
  closedDate?: string;
  scon?: string;
  econ?: string;
};

export type SchemeDetailsResponse = {
  id: string;
  name: string | null;
  startDate: string | null;
  renewalDate: string | null;
  closedDate: string | null;
  scon: string | null;
  econ: string | null;
};

export enum PensionType {
  AVC = 'AVC',
  CB = 'CB',
  CMP = 'CMP',
  DB = 'DB',
  DC = 'DC',
  HYB = 'HYB',
  SP = 'SP',
}

export enum DefaultSection {
  No = 'No',
  Yes = 'Yes',
}

export enum SetupStatus {
  Pending = 'Pending',
  Completed = 'Completed',
}

export type SectionResponse = {
  id: string;
  name: string;
  schemeId: string;
  createdDate: string;
  updatedDate: string | null;
  pensionType: PensionType;
  numberofTranches: number;
  isDefault: boolean;
  setupStatus: SetupStatus;
};

export type SectionResponsePagedResponse = {
  totalCount: number;
  items: SectionResponse[];
};

export type TranchResponse = {
  id: string;
  sectionId: string;
  name: string | null;
  benefitType: string | null;
  accrualRate: number | null;
  retirementAgeMale: number | null;
  retirementAgeFemale: number | null;
  spouseDIR: number | null;
  spouseDID: number | null;
  section: SectionDetailResponse;
};

export type SectionDetailResponse = {
  id: string;
  name: string | null;
  pensionType: PensionType;
  actualRetirementAge: number | null;
  expectedTargetRetirementAge: number | null;
  joinSchemeMinimumAge: number | null;
  joinSchemeMaximumAge: number | null;
  contributionsEnabled: boolean;
  isDefault: boolean;
  defaultExists: boolean;
  contractingOutType: string | null;
  contractingOutStartDate: string | null;
  contractingOutEndDate: string | null;
  gmpEqualisationDate: string | null;
  gmpNewJoinerEqualisationDate: string | null;
  tranches: TranchResponse[] | null;
};

export type UpdateBenefitSectionDto = {
  isDefault: boolean;
  contributionsEnabled: boolean;
  actualRetirementAge: number;
  expectedTargetRetirementAge: number;
  joinSchemeMinimumAge: number;
  joinSchemeMaximumAge: number;
  contractingOutType?: string;
  contractingOutStartDate?: string;
  contractingOutEndDate?: string;
  gmpEqualisationDate?: string;
  gmpNewJoinerEqualisationDate?: string;
};

export enum ValidationType {
  Core = 'Core',
  Bespoke = 'Bespoke',
  CoreOverride = 'CoreOverride',
  SchemeOverride = 'SchemeOverride',
}

export type AllValidationsResponse = {
  id: string;
  number: number;
  message: string | null;
  type: ValidationType;
  isEnabled: boolean;
  updatedDate: string | null;
  dataCategory: string | null;
  benefitTypes: string[] | null;
  isOverrided: boolean;
};

export type AllValidationsResponsePagedResponse = {
  totalCount: number;
  items: AllValidationsResponse[] | null;
};

export type BenefitTypeResponse = {
  name: string | null;
};

export type DataCategoryResponse = {
  name: string | null;
};

export type ScoreTypeResponse = {
  id: string;
  name: string | null;
};

export type DataItemResponse = {
  id: string;
  name: string | null;
};

export type DataItemResponsePagedResponse = {
  totalCount: number;
  items: DataItemResponse[];
};

export type ScoreTypeResponsePagedResponse = {
  totalCount: number;
  items: ScoreTypeResponse[];
};

export type CreateScoreTypeDto = {
  name: string;
};

export type CreateDataItemModel = {
  name: string;
};

export type UpdateScoreTypeDto = {
  id: string;
  name: string;
};

export type UpdateDataItemModel = {
  id: string;
  name: string;
};

export enum UploadHistoryStatus {
  Completed = 'Completed',
  Failed = 'Failed',
}

export type UploadHistoryResponsePagedResponse = {
  totalCount: number;
  items: UploadHistoryResponse[];
};

export type UploadHistoryResponse = {
  id: string;
  fileName: string | null;
  uploadedDate: string;
  uploadedByUserName: string | null;
  uploadHistoryStatus: UploadHistoryStatus;
  validationFileUploadId: string;
};

export type UploadHistoryDetailsResponse = {
  id: string;
  fileName: string | null;
  uploadedDate: string;
  uploadedByUserName: string | null;
  uploadHistoryStatus: UploadHistoryStatus;
  validationFileUploadId: string;
};

export type ValidationFileUploadResponse = {
  id: string | null;
  status: string | null;
  errors: string[] | null;
};

export enum UploadedFileStatus {
  Uploaded = 'Uploaded',
  InProgress = 'InProgress',
  Succeed = 'Succeed',
  Failed = 'Failed',
  NotUploaded = 'NotUploaded',
}

export type SearchPensionElementResponse = {
  id: string;
  name: string | null;
  schemeId: string;
  startDate: string;
  endDate: string;
  createdDate: string;
  updatedDate: string | null;
};

export type SearchPensionElementResponsePagedResponse = {
  totalCount: number;
  items: SearchPensionElementResponse[];
};

export type TranchFileUploadResponse = {
  id: string | null;
  status: string | null;
  errors: string[] | null;
};

export type CreateOverrideValidations = {
  validationId: string;
  companyId: string;
  number: number;
  schemeIds: string[];
};

export type UpdateTranchDetailsDto = {
  name: string;
  benefitType: string;
  accrualRate?: number | null;
  retirementAgeMale?: number | null;
  retirementAgeFemale?: number | null;
  spouseDIR?: number | null;
  spouseDID?: number | null;
};

export type TranchDetailsResponse = {
  id: string;
  name: string | null;
  benefitType: string | null;
  accrualRate: number | null;
  retirementAgeMale: number | null;
  retirementAgeFemale: number | null;
  spouseDIR: number | null;
  spouseDID: number | null;
};

export type TemplateSearchResponsePagedResponseWithCount = {
  totalCount: number;
  items: TemplateSearchResponse[];
};

export type TemplateHistorySearchResponsePagedResponse = {
  totalCount: number;
  items: TemplateHistorySearchResponse[];
};

export type ReportHistorySearchResponsePagedResponse = {
  totalCount: number;
  items: ReportHistorySearchResponse[];
};

export type ReportHistorySearchResponse = {
  id: string;
  analysisRunNumber: number;
  schemeName: string | null;
  reportName: string | null;
  status: ReportStatus;
  type: ReportType;
  createdDate: string;
  createdBy: string | null;
};

export type TemplateSearchResponse = {
  id: string;
  name: string | null;
  version: number | null;
  createdDate: string;
  updatedDate: string | null;
  createByUserName: string | null;
  updatedByUserName: string | null;
  status: TemplateStatus;
  type: TemplateType;
  allowToEdit: boolean;
};

export type TemplateResponse = {
  id: string;
  name: string | null;
  version: number;
  createdDate: string;
  updatedDate: string | null;
  createdBy: string | null;
  status: TemplateStatus;
  type: TemplateType;
  devExpressReport: string | null;
  assignedSchemes: SchemeResponse[];
  templateHistories: TemplateHistoryResponse[] | null;
};

export type TemplateHistoryResponse = {
  id: string;
  name: string | null;
  version: number;
  updatedDate: string | null;
  user: string | null;
  createdByUserId: string;
  status: TemplateStatus;
  devExpressReport: string | null;
  templateId: string;
};

export type TemplateHistorySearchResponse = {
  id: string;
  name: string | null;
  version: number | null;
  type: TemplateType;
  status: TemplateStatus;
  createdDate: string;
  createdBy: string | null;
};

export type CreateTemplateModel = {
  companyId: string;
  name: string;
  type?: TemplateType;
  schemeIds?: string[];
};

export type UpdateTemplateModel = {
  companyId: string;
  templateId: string;
  name: string;
  type?: TemplateType;
  schemeIds?: string[];
};

export type SaveAsTemplateModel = {
  name?: string;
  templateId: string;
  companyId: string;
};

export enum TemplateType {
  Default = 'Default',
  ClientSpecific = 'ClientSpecific',
}

export type PensionElementResponse = {
  id: string;
  name: string | null;
  startDate: string;
  endDate: string;
  sectionId: string[];
};

export type CreatePensionElementDto = {
  name: string;
  startDate: string;
  endDate: string;
  sectionId: string[];
  schemeId?: string;
  pensionElementSrcId?: string | null;
};

export type UpdatePensionElementDto = {
  name: string;
  startDate: string;
  endDate: string;
  sectionId: string[];
};

export enum ValidationStatus {
  InProgress = 'InProgress',
  Completed = 'Completed',
  Failed = 'Failed',
}

export enum ValidationResultStatus {
  Success = 'Success',
  Fail = 'Fail',
}

export enum ValidationRunType {
  Test = 'Test',
  Final = 'Final',
}

export type AnalysisHistoryResponsePagedResponse = {
  days: number;
  totalCount: number;
  items: AnalysisHistoryResponse[];
};

export type AnalysisHistoryResponse = {
  id: string;
  analysisRunNumber: number;
  runByUserName: string | null;
  startDate: string;
  endDate: string | null;
  validationRunType: ValidationRunType;
  status: ValidationStatus;
};

export type UpdateValidationRunTypeDto = {
  runType: ValidationRunType;
  validationRunId: string;
};

export type SearchValidationResultsResponse = {
  id: string;
  number: number;
  message: string | null;
  memberReference: string | null;
  memberStatus: string | null;
  scoreTypeNames: string[] | null;
  dataItemsNames: string[] | null;
};

export type SearchValidationResultsResponsePagedResponse = {
  totalCount: number;
  items: SearchValidationResultsResponse[] | null;
};

export type MemberStatusResponse = {
  id: string;
  name: string | null;
};

export type SearchValidationGroupingsResponsePagedResponse = {
  totalCount: number;
  items: SearchValidationGroupingsResponse[] | null;
};

export type SearchValidationGroupingsResponse = {
  id: string;
  validationId: string;
  scheme: string | null;
  schemeId: string;
  number: number;
  message: string | null;
  dataCategory: string | null;
  dataItems: DataItemResponse[] | null;
  memberStatuses: MemberStatusResponse[] | null;
  scoreTypes: ScoreTypeResponse[] | null;
};

export type ValidationScoringSummaryResponse = {
  scoreTypeId: string;
  scoreType: string | null;
  memberFails: number;
  totalMembers: number;
  uniqueValidations: number;
  memberFailsPercentage: number;
  scoreRate: number;
};

export type UpdateValidationGroupingScoreTypesDto = {
  scoreTypeIds: string[] | null;
  schemeId: string;
  validationId: string;
};

export type UpdateSchemeValidationMemberStatusesDto = {
  memberStatusId: string[] | null;
  schemeId: string;
  validationId: string;
};

export type ValidationGroupingFileUploadResponse = {
  id: string | null;
  status: string | null;
  errors: string[] | null;
};

export type AnalysisHistoryDetailResponse = {
  id: string;
  uploadId: string;
  schemeId: string;
  analysisRunNumber: number;
  runByUserName: string | null;
  startDate: string;
  endDate: string | null;
  validationRunType: ValidationRunType;
  status: ValidationStatus;
  schemeName: string | null;
  hasErrorMessages: boolean | null;
};

export type ScoreTypeDetailsResponse = {
  scoreTypeId: string;
  scoreType: string | null;
  memberFails: number;
  totalMembers: number;
  uniqueValidations: number;
  memberFailsPercentage: number;
  scoreRate: number;
};

export type AnalysisHistoryMemberStatusResponse = {
  memberStatus: string | null;
  memberFails: number;
  totalMembers: number;
  memberFailsPercentage: number;
  scoreRate: number;
};

export type DataItemScoringDetailsResponse = {
  scoreTypeId: string;
  scoreType: string | null;
  dataItemId: string;
  dataItem: string | null;
  memberFails: number;
  totalMembers: number;
  uniqueValidations: number;
  memberFailsPercentage: number;
  scoreRate: number;
};

export type ValidationResponse = {
  id: string;
  number: number;
  isEnabled: boolean;
  type: ValidationType;
  dataCategory: string | null;
  scoreTypes: string[] | null;
  dataItems: string[] | null;
  benefitTypes: string[] | null;
  schemes: string[] | null;
  message: string | null;
  description: string | null;
  query: string | null;
  hasAllSchemeAccess: boolean;
};

export type UpdateValidationStatusDto = {
  isEnabled: boolean;
};

export type CreateReportModel = {
  companyId: string;
  templateId: string;
  validationRunId: string;
};

export type UpdateValidationDetailsDto = {
  companyId: string;
  number: number;
  type: ValidationType;
  message: string;
  query: string;
  benefitTypes: string[];
  dataItemIds: string[];
  scoreTypeIds: string[];
  dataCategory: string;
  schemeIds: string[] | null;
  description?: string | null;
  isEnabled: boolean;
};
